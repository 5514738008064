#root {
    height: 100vh;
    position: relative;
}

.encointer-map {
    position: relative;
    height: 100% !important;
    width: 100%;
    overflow: hidden !important;
    border: 0 !important;
}
.encointer-map > .component-wrapper {
    overflow: hidden !important;
    position: relative;
    height: 100%;
}

.encointer-map .details-sidebar {
    background: white;
}

.encointer-map .details-sidebar .message {
    overflow-wrap: break-word;
}
.node-switch-widget {
    background: rgb(245, 243, 241);
}
.node-switch-widget > .ui.segment {
    background: transparent;
}

.node-switch-widget .isSelected> .groupHeader {
    padding-bottom: 0.5rem;
}

.node-switch-widget .endpoint-network {
    border-left: 0.25rem solid transparent;
    border-radius: 0.25rem;
    cursor: pointer;
    margin: 0 0 0.25rem 0;
    padding: 0.375rem 0.5rem 0.375rem 1rem;
    position: relative;
}

.node-switch-widget .endpoint-network.isSelected,
.node-switch-widget .endpoint-network:hover {
    background: #fffefd;
}

.node-switch-widget .endpoint-network.highlight--border {
    border-color: rgb(241, 145, 53) !important;
}

.node-switch-widget .endpoint-section {
    margin-bottom: .5rem;
    margin-top: 0.4rem;
}

.node-switch-widget .endpoint-value {
    font-size: 1.5rem;
}

.node-switch-widget .endpoint-icon {
    height: 50px;
    margin-right: 1rem;
    width: 50px;
    background: #fff;
    border-radius: 25px;
    padding: 2px;
    box-shadow: 0 0 0 1px rgba(105,204,57,0.7);
}

.node-switch-widget .endpoint-address {
    margin: .5em 0;
    padding-left: 1.4em;
}

.encointer-map .node-switch-widget .endpoint-section {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    position: relative;
}

.encointer-map-wrapper {
    overflow: hidden !important;
    height: 100%;
    position: relative;
    border: 0 !important;
    margin: 0;
    padding: 0 !important;
    background: black;
}

.encointer-map-wrapper > .leaflet-container {
    height: 100%;
    width: 100%;
    z-index: 0;
}

.encointer-map .details-sidebar > .ui.segment {
    background: none;
}

.encointer-map .encointer-map-floating-widgets {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0 !important;
    padding-top: 1px !important;

    width: auto !important;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.encointer-map .encointer-map-floating-widgets .item:before {
    display: none;
}

.encointer-map .encointer-map-floating-widgets .encoiner-menu-button {
    width: 100% !important;
}

.encointer-map .encointer-map-node-info {
    z-index: 1;
    position: absolute !important;
    bottom: 0;
    left: .5em;
    margin-top: 0 !important;
    padding-top: 0 !important;
    flex-direction: row;
    width: 500px;
    height: 67px;
    cursor: pointer;
}

.encointer-map .encointer-map-node-info > div {
    padding: .5em;
}

.encointer-map .encointer-map-node-info .content .meta {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
}

.encointer-map .encointer-map-node-info > .loading {
    margin-top: 11px;
}

.encointer-map .encointer-map-node-info > .loading > .error {
    font-weight: bold;
    color: #800;
    font-size: 1rem;
}

.encointer-map .encointer-map-node-info > .info {
    width: 340px;
}

.encointer-map .encointer-map-node-info > .blocks {
    padding: .8em .5em;
    margin: 0 .5rem 0 0;
    width: 160px;
}

.encointer-map .encointer-map-node-info .blocks .meta {
    text-align: center;
    font-size: 1rem;
    color: #000;
}

.encointer-map .encointer-map-node-info .blocks div {
    line-height: 1.0rem;
    font-size: 0.75rem;
    text-align: right;
    color: rgba(0,0,0,.4);
}

.encointer-map .encointer-map-node-info .blocks .loading {
    margin-top: 8px;
}

.encointer-map .encointer-map-node-info .blocks .ceremony>span {
    padding-right: 0.6rem;
    display: inline-block;
}

.encointer-map .encointer-map-ceremony-phase {
    z-index: 1;
    position: absolute !important;
    top: 3px;
    left: 5em;
    margin-top: 0 !important;
    padding-top: 0 !important;
    width: auto !important;
    box-shadow: none;
}

.encointer-map .encointer-map-ceremony-phase .ui.steps.small-screen .step {
    padding: .5rem 1rem;
}

.encointer-map .encointer-map-ceremony-phase .ui.steps .step .content {
    align-self: flex-start !important;
}

.encointer-map .encointer-map-ceremony-phase .ui.steps .step.active .description {
    font-size: 1.1rem;
    line-height: 1rem;
}

.encointer-map .encointer-map-ceremony-phase .steps .step-registering.step:before {
    content: "1" !important;
}

.encointer-map .encointer-map-ceremony-phase .steps .step-assigning.step:before {
    content: "2" !important;
}

.encointer-map .encointer-map-ceremony-phase .steps .step-attesting.step:before {
    content: "3" !important;
}

.encointer-map .encointer-map-ceremony-phase .content {
    display: flex;
    padding: 0 5px 0 5px !important;
}

.encointer-map .encointer-map-ceremony-phase div {
    margin: 0;
}

.encointer-map .encointer-map-ceremony-phase .statistic.small > .value {
    font-size: 2rem !important;
}

.encointer-map .encointer-map-ceremony-phase .step .title > .label {
    margin-left: .5em !important;
    margin-top: -4px !important;
}

@keyframes pulse-active
{
  0% {
    box-shadow: 0 0 0 0px rgba(105,204,57,0.7);
  }
  100% {
    box-shadow: 0 0 0 45px rgba(110,204,57,0);
  }
}

.encoiner-community-icon {
}

.encoiner-community-icon.pulse, .encoiner-cluster-custom.pulse {
    animation: pulse-active 1s infinite;
}
.encoiner-community-icon.yellow, .encoiner-cluster-custom.yellow {
    background-color: rgba(255,204,57,0.6);
}
.encoiner-community-icon.yellow div, .encoiner-cluster-custom.yellow div {
    background-color: rgba(255,204,1,1);
}
.encoiner-community-icon.yellow span {
    text-shadow: 2px -1px 3px rgba(180,80,11,1), 0px 2px 2px rgba(220,105,1,1 );
}

.encoiner-community-icon.red, .encoiner-cluster-custom.red {
    background-color:  rgba(255,55,10,0.6);
}
.encoiner-community-icon.red div, .encoiner-cluster-custom.red div {
    background-color: rgba(255,55,0,1);
}
.encoiner-community-icon.red span {
    text-shadow: 2px -1px 3px rgba(180,0,11,1), 0px 2px 2px rgba(220,5,1,1 );
}
.encoiner-community-icon div {
    background-color: rgba(81,204,0,0.8);
}

.encoiner-community-icon {
    background-color: rgba(110,204,57,0.4);
}

.encoiner-community-icon span {
    display: block;
    position: relative;
    font-size: 18px;
    line-height: 30px;
    color: #fff;
    font-weight: bold;
    padding-bottom: 6px;
    font-style: normal;
    text-shadow: 2px -1px 3px rgba(12,60,2,1), 0px 2px 2px rgba(20,155,1,1 );
}
